import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Text, Grid, Heading } from "theme-ui"
import { getArticlePath } from "../utils/path"

const ArticleThumb = ({ article }) => {
  return (
    <Box
      sx={{
        "&:hover": {
          a: {
            h2: { textDecoration: "underline" },
          },
        },
        mb: 4,
      }}
    >
      <Link to={getArticlePath(article)}>
        <Grid columns={[1, "2fr 4fr"]} gap={[16, 64]}>
          <Box>
            <GatsbyImage image={article.heroImage.gatsbyImageData} alt="" />
          </Box>
          <Box>
            <Heading
              variant="h3"
              as="h2"
              sx={{ fontSize: [4, 3, 5], mt: [3, 0, 4] }}
            >
              {article.title}
            </Heading>
            <Text as="p" sx={{ fontSize: 2, mb: 3 }}>
              {article.seo.description}
            </Text>
            <Text as="p" sx={{ color: "textGrey", fontSize: 1 }}>
              {article.meta.firstPublishedAt}
            </Text>
          </Box>
        </Grid>
      </Link>
    </Box>
  )
}

export default ArticleThumb

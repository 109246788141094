import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Container, Box } from "theme-ui"
import PageHero from "../templates/pageHero"
import ArticleThumb from "../components/articleThumb"
import { getBlogPath } from "../utils/path"

const Blog = ({ data: { page, site, articles } }) => {
  console.log(page)
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getBlogPath(page),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      <Container sx={{ mt: [3, 5], mb: [3, 5] }}>
        <Box>
          {articles.nodes.map(article => (
            <ArticleThumb key={article.id} article={article} />
          ))}
        </Box>
        <Box></Box>
      </Container>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogQuery {
    site: datoCmsSite {
      locales
    }
    page: datoCmsBlogPage {
      id
      title
      slug
      locale
      heroImage {
        gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
      }
      _allSlugLocales {
        value
        locale
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        locale
        slug
        heroImage {
          gatsbyImageData(
            width: 720
            placeholder: NONE
            forceBlurhash: false
            imgixParams: { duotone: "993416,000000", duotoneAlpha: 10 }
          )
        }
        meta {
          firstPublishedAt(formatString: "dddd D MMMM YYYY", locale: "it")
        }
        body {
          value
        }
        _allSlugLocales {
          value
          locale
        }
        seo {
          description
        }
      }
    }
  }
`
